import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { ACCESS_TOKEN_NAME, TNET_ACCOUNTS_URL } from "src/env"
import RegisterService from "../services/RegisterService"
import { QUERY_KEYS } from "./queryKeys"
import GlobalService from "../services/GlobalService"
import { CacheTime } from "src/utils/configs/apiCacheTime"

const useGlobal = () => {
  const globalInfoQuery = useQuery({
    queryKey: [QUERY_KEYS.GET_GLOBAL_USER_INFO],
    queryFn: () => getUserInfo(),
    staleTime: CacheTime.MAIN_PAGE,
    cacheTime: CacheTime.MAIN_PAGE,
    enabled: true
  })

  const postSendOTPMutation = useMutation({
    mutationKey: [QUERY_KEYS.POST_SEND_OTP],
    mutationFn: postSendOTP,
    onError: (err, _, context) => {
      console.error("Error while updating user:", err)

      return "ტესტ"
      // getting `userBeforeMutation` from `onMutate`'s return
      // ✅ here, `context` is typed correctly
    }
  })

  const imageStaticMyGeMutation = useMutation({
    mutationKey: ["static-image-upload"],
    mutationFn: (data: FormData) => postImageStaticMyGe(data),
    onError: (err, _, context) => {
      console.error("Error while updating user:", err)
      return "ტესტ"
      // getting `userBeforeMutation` from `onMutate`'s return
      // ✅ here, `context` is typed correctly
    }
  })

  const profilePhotoMutation = useMutation({
    mutationFn: (data: { path: string }) => postProfilePhoto(data),
    onError: (err, _, context) => {
      console.error("profilePhotoMutation:", err)
      return "ტესტ"
      // getting `userBeforeMutation` from `onMutate`'s return
      // ✅ here, `context` is typed correctly
    }
  })

  return {
    postSendOTPMutation,
    imageStaticMyGeMutation,
    globalInfoQuery,
    profilePhotoMutation
  }
}

export const postSendOTP = async (data: { phone: number }) => {
  try {
    const response: any = await axios.post(`${TNET_ACCOUNTS_URL}/api/ka/phone/otp`, data)

    return response.data
  } catch (e: any) {
    console.error("error postSendOTP")
    throw e // Rethrow the error
  }
}

export const getUserInfo = async () => {
  try {
    const response: any = await GlobalService.getUserInfo()
    return response.data
  } catch (e: any) {}
}

export const postUserInfo = async (data: any) => {
  try {
    const response: any = await GlobalService.postUserInfo(data)
    return response.data
  } catch (e: any) {}
}

export const updatePassword = async (data: { current_password: string; password: string }) => {
  return await GlobalService.updatePassword(data)
}

export const updatePhone = async (data: { phone: string; code: string }) => {
  return await GlobalService.updatePhone(data)
}

export interface ImageStaticPostData {
  Func: "UploadProfileImage"
  SiteID: "5"
  File: File
}
export const postImageStaticMyGe = async (data: FormData) => {
  try {
    const response: any = await RegisterService.postImageStaticMyGe(data)

    return response.data
  } catch (e: any) {
    console.error("error postSendOTP")
    throw e // Rethrow the error
  }
}

export const postProfilePhoto = async (data: { path: string }) => {
  try {
    const response: any = await RegisterService.postProfilePhoto(data)

    return response.data
  } catch (e: any) {
    console.error("error postSendOTP")
    throw e // Rethrow the error
  }
}

export default useGlobal
