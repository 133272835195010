import { getMainPageV2 } from "./../queryHooks/useIndex"
import Cookie from "src/utils/configs/Cookie"
import HttpService from "./HttpService"

class LandingService extends HttpService {
  getMainPageData() {
    const AccessToken = Cookie.get("AccessToken")
    if (AccessToken) {
      return this.get("/api/natural-person/main-page")
    }
    return this.get("/api/public/main-page")
  }

  getMainPageV2() {
    return this.get("/api/public/main-page-v2")
  }

  getKeywords(params: { keyword: string }) {
    return this.get("/api/public/keyword-search", params)
  }

  getPopularCategories() {
    return this.get("/api/public/popular-categories")
  }

  getTopCompanies() {
    return this.get("/api/public/companies/top")
  }

  getBenefits() {
    return this.get("/api/public/benefits")
  }
}

export default new LandingService()
