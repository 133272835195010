export const employementTypeOptions = [
  { id: 1, label: "სრული განაკვეთი", value: "full_time" },
  { id: 2, label: "ნახევარი განაკვეთი", value: "part_time" },
  { id: 3, label: "საათობრივი", value: "hourly" },
  { id: 4, label: "ცვლები", value: "shifts" }
]
export const getEmploymenTypeLabel = (employment_type_value: string) => {
  const employementTypeObject = employementTypeOptions.find(o => o.value === employment_type_value)

  if (employementTypeObject) {
    return employementTypeObject.label
  }

  return employment_type_value
}

export const jobTypeOptions = [
  { id: 1, label: "ოფისიდან", value: "on_site" },
  { id: 2, label: "დისტანციური", value: "remote" },
  { id: 3, label: "ჰიბრიდული", value: "hybrid" },
  { id: 4, label: "თავისუფალი გრაფიკი", value: "freelance" }
]
export const getJobTypeLabel = (job_type_value: string) => {
  const jobTypeObject = jobTypeOptions.find(o => o.value === job_type_value)

  if (jobTypeObject) {
    return jobTypeObject.label
  }

  return job_type_value
}

export const hireTypeOptions = [
  { id: 1, label: "სასწრაფო", value: "urgent" },
  { id: 2, label: "საკონტრაკტო", value: "contract" },
  { id: 3, label: "ინტერნი", value: "intern" },
  { id: 4, label: "სეზონური", value: "seasonal" },
  { id: 5, label: "ფრილანსი", value: "freelance" }
]
export const getHireTypeLabel = (hire_type_value: string) => {
  const hireTypeObject = hireTypeOptions.find(o => o.value === hire_type_value)

  if (hireTypeObject) {
    return hireTypeObject.label
  }

  return hire_type_value
}
